<template>
	<section class="container">
		<div class="row mt-3">
			<template v-if="$can('Add', 'Company', 'Employees')">
				<div class="col-auto mt-1">
					<b-button variant="success" @click="addPension"> Opret ny pensionsaftale</b-button>
				</div>
			</template>
		</div>
		<br />
		<b-card title="Pensionsaftaler">
			<b-form-input class="input col-12 mb-3" v-model="globalfilter" placeholder="Søg" />
			<div v-if="companyPensions && companyPensions.length > 0" class="row">
				<div v-for="(pension, index) in companyPensions" :key="index" class="col-md-4 mb-4">
					<b-card
						class="insurance-card"
						@click="navigateTo(pension.id)"
						:title="pension.pension.name"
						:sub-title="getSupplierName(pension.pension.supplierId)"
					>
						<div class="card-body text-center">
							<img
								v-if="getSupplierImage(pension.pension.supplierId)"
								:src="getSupplierImage(pension.pension.supplierId)"
								class="img-fluid rounded-circle mb-3"
								style="width: 100px; height: 100px; object-fit: cover"
								alt="Pension Image"
							/>
							<b-card-text>
								<b>Grupper: </b>
								<b-badge v-for="(groupId, index) in pension.groups" :key="index" :variant="'success'" class="mr-1">
									<h6>{{ getGroupName(groupId) }}</h6>
								</b-badge>
							</b-card-text>
							<b-card-text>
								<b>Startdato: </b>
								{{ getDateFull(pension.pension.startDate.value) }}
							</b-card-text>
						</div>
						<div class="card-footer bg-transparent border-top-0">
							<a class="stretched-link card-link">Se detaljer <b-icon icon="arrow-right-circle-fill"></b-icon></a>
						</div>
					</b-card>
				</div>
			</div>
			<div v-else class="row justify-content-center">
				<div class="col-12 text-center mt-5">
					<h3 class="text-muted">Ingen pensionsaftaler fundet</h3>
					<p>Start med at oprette din første pensionsaftale.</p>
				</div>
			</div>
		</b-card>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import dateMixin from '@/mixins/dateMixin'
export default {
	name: 'CompanyPensionList',
	mixins: [dateMixin],
	components: {
		BaseTable,
	},
	data() {
		return {
			globalfilter: '',
			calucationType: '%',
			fields: [
				{
					key: 'name',
					label: 'Aftalenavn',
					sortable: true,
				},
				{
					key: 'groups',
					label: i18n.t('employeeList.table.group'),
					sortable: true,
				},
				{
					key: 'supplier',
					label: 'Leverandør',
					sortable: true,
				},
			],
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		getDateFull(date) {
			var options = { year: 'numeric', month: 'long', day: 'numeric' }
			if(!date) return '-'
			return new Date(date).toLocaleDateString('da-DK', options)
		},
		getSupplierImage(supplierId) {
			const supplier = this.suppliers.find((g) => g.id === supplierId)
			return supplier?.imageUrl ?? ''
		},
		getSupplierName(supplierId) {
			const supplier = this.suppliers.find((g) => g.id === supplierId)
			return supplier?.name ?? ''
		},
		getGroupName(groupId) {
			const group = this.groups.find((g) => g.id === groupId)
			return group ? group.name : ''
		},
		async addPension() {
			const { value: pensionName } = await this.$swal
				.fire({
					title: 'Indsæt navn for pensionsaftale',
					input: 'text',
					inputValidator: (value) => {
						if (!value) {
							return 'En pensionsaftale skal have et navn'
						}
					},
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ja opret!',
					showLoaderOnConfirm: true,
					preConfirm: async (pensionName) => {
						try {
							var data = {
								pension: { name: pensionName },
							}
							var companyId = this.companyId
							var result = await this.$store.dispatch('companyVuex/addCompanyPension', { companyId, data })
							return result
						} catch (error) {
							console.log(error)
							this.$swal.showValidationMessage(`Request failed: ${error.message}`)
						}
					},
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.toast('Success', 'En pensionsaftale er blevet oprettet', true)
						this.clicked = false
						//this.$router.go()
					}
				})
		},

		navigateTo(pensionId) {
			this.$router.push({ name: 'CompanyPension', params: { companyId: this.companyId, pensionId: pensionId } })
		},
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		companyCvr() {
			return this.company.companyInfo.cvr
		},
		company() {
			var company = this.$store.getters['companyVuex/company']
			return company
		},
		isBusy() {
			return !this.$store.getters['companyVuex/isReady']
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
		groups() {
			return this.$store.getters['companyVuex/groups']
		},
		companyPensions() {
			return this.$store.getters['companyVuex/pensions']?.sort((a, b) => (a?.pension?.name?.toLowerCase() > b?.pension?.name?.toLowerCase() ? 1 : -1))
		},
	},
}
</script>

<style scoped>
.insurance-card {
	cursor: pointer;
	transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.insurance-card:hover {
	transform: translateY(-5px);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.card-footer a {
	text-decoration: none;
	color: #007bff; /* Bootstrap primary color */
}
</style>
